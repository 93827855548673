import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
const PayerServerById = () => {
  const [payerServerId, setPayerServerId] = useState("");
  const [payerServerData, setPayerServerData] = useState(null);

  const handleSearch = async () => {
    const apiEndpoint = window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + '/PayerServer/' + payerServerId;
    const response = await fetch(apiEndpoint);
    const data = await response.json();
    setPayerServerData(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };
  
  const renderTable = (data) => {
    const header = [];
    const rows = [];

    Object.entries(data).forEach(([property, value], index) => {
      const uniqueKeyForHeader = `header-${property}-${index}`;
      const uniqueKeyForRow = `row-${property}-${index}`;
    
      header.push(
        <th key={uniqueKeyForHeader} scope="col">{property}</th>
      );

      const renderValue = (typeof value === 'object' && value !== null) ? JSON.stringify(value) : value;

      rows.push(
        <td key={uniqueKeyForRow}>{renderValue}</td>
      );
    });

    return (
      <table className="table table-striped table-hover">
        <thead>
          <tr>{header}</tr>
        </thead>
        <tbody>
          <tr>{rows}</tr>
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <input 
          type="text"
          placeholder="Enter Payer Server ID"
          value={payerServerId}
          onChange={(e) => setPayerServerId(e.target.value)}
        />
        <Button type="submit">Search</Button>
      </Form>

      {payerServerData ? (
        <>
          <h1>Payer Server</h1>
          {renderTable(payerServerData)}
        </>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default PayerServerById;
