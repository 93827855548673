import React from "react";

type Props = {};

const CopiedFileHeaders = (props: Props) => {
  return (
    <thead>
      <tr>
        <th>Id</th>
        <th>Payer Id</th>
        <th>File Name</th>
        <th>Inserted At</th>
        <th>Delete</th>
      </tr>
    </thead>
  );
};

export default CopiedFileHeaders;
