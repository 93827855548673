import React, { useState, useEffect } from "react";
import PayerServerById from "./PayerServerById";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
function PayerServer() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    host: "",
    port: 0,
    userName: "",
    password: "",
    folderDir: "",
    oneDrivePath: "",
    name: "",
  });
  const [editPayer, setEditPayer] = useState(null);
  const [editHost, setEditHost] = useState("");
  const [editPort, setEditPort] = useState(0);
  const [editUserName, setEditUserName] = useState("");
  const [editFolderDir, setEditFolderDir] = useState("");
  const [editPassword, setEditPassword] = useState("");
  const [editName, setEditName] = useState("");
  const [editOneDrivePath, setEditOneDrivePath] = useState("");
  const openModal = (payerId, pay) => {
    if (!pay) {
      console.log("payer object is undefined");
      return;
    }
    setEditPayer(payerId);
    setEditHost(pay.host);
    setEditPort(pay.port);
    setEditUserName(pay.userName);
    setEditPassword(pay.password);
    setEditFolderDir(pay.folderDir);
    setEditName(pay.name);
    setEditOneDrivePath(pay.oneDrivePath);
  };

  const closeModal = () => {
    setEditPayer(null);
  };

  async function fetchData() {
    try {
      const response = await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/PayerServer"
      );
      const result = await response.json();
      setData(result);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/PayerServer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const result = await response.json();
      fetchData();
      setFormData({
        host: "",
        port: 0,
        userName: "",
        password: "",
        folderDir: "",
        oneDrivePath: "",
        name: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  if (loading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }

  if (!data) {
    return <div>No data available</div>;
  }

  async function handleDelete(id) {
    const confirmDelete = window.confirm("Are you sure you want to delete?");

    if (!confirmDelete) {
      return;
    }

    try {
      await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/PayerServer/" + id,
        {
          method: "DELETE",
        }
      );
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }

  const handlePut = async (e) => {
    e.preventDefault();
    const id = editPayer;
    const payload = {
      id,
      host: editHost,
      port: editPort,
      userName: editUserName,
      password: editPassword,
      folderDir: editFolderDir,
      name: editName,
      oneDrivePath: editOneDrivePath,
    };
    try {
      const response = await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/PayerServer/" + id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    closeModal();
    fetchData();
  };

  const handleFormChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <div>
      <h1>NewPayer</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Host"
            name="host"
            value={formData.host}
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="number"
            placeholder="Port"
            name="port"
            value={formData.port}
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Username"
            name="userName"
            value={formData.userName}
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Folder Directory"
            name="folderDir"
            value={formData.folderDir}
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Name"
            name="host"
            value={formData.name}
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="OneDrivePath"
            name="oneDrivePath"
            value={formData.oneDrivePath}
            onChange={handleFormChange}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <br />
      <div>
        <h3>Get By ID</h3>
        <PayerServerById />
      </div>
      <h1>Payers</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            {data.length > 0 &&
              Object.keys(data[0]).map((key, index) => {
                if (key !== "oneDrivePath") {
                  return (
                    <th key={"PayersTableHead" + index} scope="col">
                      {key}
                    </th>
                  );
                }
                return null;
              })}
            <th key={"PayersTableHeadOneDrive"} scope="col">
              OneDrivePath
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={"PayersTableRow" + index}>
              {Object.keys(item).map((key, keyIndex) => {
                if (key !== "oneDrivePath") {
                  return (
                    <td key={"PayersTableData" + keyIndex}>{item[key]}</td>
                  );
                }
                return null;
              })}
              <td>{item.oneDrivePath}</td>
              <td>
                <Button variant="danger" onClick={() => handleDelete(item.id)}>
                  Delete
                </Button>
              </td>
              <td>
                <Button
                  variant="primary"
                  onClick={() => openModal(item.id, item)}
                >
                  Edit
                </Button>
              </td>
              <td>
                <Modal show={editPayer === item.id} onHide={closeModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Payer</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={handlePut}>
                      <Form onSubmit={handlePut}>
                        <Form.Group className="mb-3">
                          <Form.Label>Host</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Host"
                            value={editHost}
                            onChange={(e) => setEditHost(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Port</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Port"
                            value={editPort}
                            onChange={(e) =>
                              setEditPort(Number(e.target.value))
                            }
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Username</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Username"
                            value={editUserName}
                            onChange={(e) => setEditUserName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            required
                            placeholder="Password"
                            value={editPassword}
                            onChange={(e) => setEditPassword(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Folder Directory</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Folder Directory"
                            value={editFolderDir}
                            onChange={(e) => setEditFolderDir(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            value={editName}
                            onChange={(e) => setEditName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>OneDrivePath</Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            value={editOneDrivePath}
                            onChange={(e) =>
                              setEditOneDrivePath(e.target.value)
                            }
                          />
                        </Form.Group>
                      </Form>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button type="submit" onClick={handlePut}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default PayerServer;
