import { IPayer } from "../../Config/Types";

type Props = { payer: IPayer };

const PayerTable = ({ payer }: Props) => {
  return (
    <table className="table table-bordered table-striped mt-3">
      <tbody>
        <tr>
          <th>Id</th>
          <td>{payer.id}</td>
        </tr>
        <tr>
          <th>Name</th>
          <td>{payer.name}</td>
        </tr>
        <tr>
          <th>Host</th>
          <td>{payer.host}</td>
        </tr>
        <tr>
          <th>Username</th>
          <td>{payer.userName}</td>
        </tr>
        <tr>
          <th>Folder Directory</th>
          <td>{payer.folderDir}</td>
        </tr>
        <tr>
          <th>OneDrive Path</th>
          <td>{payer.oneDrivePath}</td>
        </tr>
        <tr>
          <th>Port</th>
          <td>{payer.port}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default PayerTable;
