import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ICopiedFile } from "../Config/Types";
import CopiedFileTable from "../Components/File/CopiedFileTable";
import PayerTable from "../Components/Payer/PayerTable";

const CopiedFilesById = () => {
  const { fileId } = useParams();
  const [fileData, setFileData] = useState<ICopiedFile | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const foo = async () => {
      const apiEndpoint =
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/File/" + fileId;
      const response = await fetch(apiEndpoint);
      const data = await response.json();
      setFileData(data);
    };
    foo();
  }, [fileId]);

  return (
    <div>
      {fileData ? (
        <>
          <h1>File</h1>
          <CopiedFileTable
            files={[fileData]}
            deleteCallback={() => {
              navigate("/");
            }}
          />
          <h1>Payer</h1>
          <PayerTable payer={fileData.payer} />
        </>
      ) : (
        <p>No file found for the given ID</p>
      )}
    </div>
  );
};

export default CopiedFilesById;
