import { ICopiedFile } from "../../Config/Types";
import { Button } from "react-bootstrap";
import { deleteFile } from "../../Fetches";

type Props = { file: ICopiedFile; deleteCallback?: (_: string) => void };

const CopiedFileTableRow = ({ file, deleteCallback }: Props) => {
  return (
    <tr>
      <td>{file.id}</td>
      <td>{file.payerId}</td>
      <td>{file.fileName}</td>
      <td>{file.insertedAt}</td>
      <td>
        <Button
          variant="danger"
          onClick={() => deleteFile(file.id, deleteCallback)}
        >
          Delete
        </Button>
      </td>
    </tr>
  );
};

export default CopiedFileTableRow;
