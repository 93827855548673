import React, { useState, useEffect } from "react";
import RecipientServerById from "./RecipientServerById";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

function RecipientsServer() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [host, setHost] = useState("");
  const [port, setPort] = useState(0);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [folderDir, setFolderDir] = useState("");
  const [editRecip, setEditRecip] = useState(null);
  const [editHost, setEditHost] = useState("");
  const [editPort, setEditPort] = useState(0);
  const [editUserName, setEditUserName] = useState("");
  const [editFolderDir, setEditFolderDir] = useState("");
  const [editPassword, setEditPassword] = useState("");
  const [name, setName] = useState("");
  const [editName, setEditName] = useState("");

  const openModal = (recipId, recip) => {
    if (!recip) {
      console.log("Recip object is undefined");
      return;
    }
    setEditRecip(recipId);
    setEditHost(recip.host);
    setEditPort(recip.port);
    setEditUserName(recip.userName);
    setEditPassword(recip.password);
    setEditFolderDir(recip.folderDir);
    setEditName(recip.name);
  };

  const closeModal = () => {
    setEditRecip(null);
  };

  async function fetchData() {
    try {
      const response = await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/RecipientServer"
      );
      const result = await response.json();
      setData(result);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }

  if (!data) {
    return <div>No data available</div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      host,
      port,
      userName,
      password,
      folderDir,
      name,
    };
    try {
      const response = await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/RecipientServer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      console.log("POST Response:", result);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    fetchData();

    setHost("");
    setPort(0);
    setUserName("");
    setFolderDir("");
    setPassword("");
    setName("");
  };

  async function handleDelete(id) {
    const confirmDelete = window.confirm("Are you sure you want to delete?");

    if (!confirmDelete) {
      return;
    }

    try {
      await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] +
          "/RecipientServer/" +
          id,
        {
          method: "DELETE",
        }
      );
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }

  const handlePut = async (e) => {
    e.preventDefault();
    const id = editRecip;
    const payload = {
      id,
      host: editHost,
      port: editPort,
      userName: editUserName,
      password: editPassword,
      folderDir: editFolderDir,
      name: editName,
    };

    try {
      const response = await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] +
          "/RecipientServer/" +
          id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    closeModal();
    fetchData();
  };

  return (
    <div>
      <h1>NewRecipient</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Host"
            value={host}
            onChange={(e) => setHost(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="number"
            placeholder="Port"
            value={port}
            onChange={(e) => setPort(Number(e.target.value))}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Username"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Folder Directory"
            value={folderDir}
            onChange={(e) => setFolderDir(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <br />
      <div>
        <h3>Get By ID</h3>
        <RecipientServerById />
      </div>
      <h1>Recipients</h1>
      <table className="table table-bordered table-striped mt-3">
        <thead>
          <tr>
            {data.length > 0 &&
              Object.keys(data[0]).map((key, index) => (
                <th key={"RecipientsTableHeader" + index} scope="col">
                  {key}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={"RecipientsTableRow" + index}>
              {Object.keys(item).map((key, keyIndex) => (
                <td key={"RecipientsTableData" + keyIndex}>{item[key]}</td>
              ))}
              <td>
                <Button onClick={() => handleDelete(item.id)} variant="danger">
                  Delete
                </Button>
              </td>
              <td>
                <Button onClick={() => openModal(item.id, item)}>Edit</Button>
              </td>
              <td>
                <Modal show={editRecip === item.id} onHide={closeModal} size="">
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Recipient</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={handlePut}>
                      <Form.Group className="mb-3">
                        <Form.Label>Host</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Host"
                          value={editHost}
                          onChange={(e) => setEditHost(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Port</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Port"
                          value={editPort}
                          onChange={(e) => setEditPort(Number(e.target.value))}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Username"
                          value={editUserName}
                          onChange={(e) => setEditUserName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          value={editPassword}
                          onChange={(e) => setEditPassword(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Folder Directory</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Folder Directory"
                          value={editFolderDir}
                          onChange={(e) => setEditFolderDir(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          value={editName}
                          onChange={(e) => setEditName(e.target.value)}
                        />
                      </Form.Group>
                    </form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handlePut}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RecipientsServer;
