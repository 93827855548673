import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import CopiedFiles from "./ControllerContent/CopiedFiles";
import PayerServer from "./ControllerContent/PayerServer";
import RecipientsServer from "./ControllerContent/RecipientsServer";
import Subscriptions from "./ControllerContent/Subscriptions";
import CopiedFilesById from "./ControllerContent/CopiedFilesById";
import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import React, { useState } from "react";
import {
  Navbar,
  Nav,
  Container,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";

function App() {
  const [fileId, setFileId] = useState("");

  const handleFileIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileId(event.target.value);
  };

  return (
    <BrowserRouter>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarNav" />
          <Navbar.Collapse id="navbarNav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/copied-files">
                Copied Files
              </Nav.Link>
              <Nav.Link as={Link} to="/payerServer">
                PayerServer
              </Nav.Link>
              <Nav.Link as={Link} to="/recipientsServer">
                RecipientsServer
              </Nav.Link>
              <Nav.Link as={Link} to="/subscriptions">
                Subscriptions
              </Nav.Link>
            </Nav>
            <Form className="d-flex">
              <FormControl
                type="text"
                value={fileId}
                onChange={handleFileIdChange}
                placeholder="Enter File ID"
                className="mr-2"
                style={{ width: "70%" }}
              />
              <Button
                href={`/GetFilesById/${fileId}`}
                variant="outline-success"
              >
                Search
              </Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Routes>
          <Route path="/" element={<CopiedFiles />} />
          <Route path="/copied-files" element={<CopiedFiles />} />
          <Route path="/payerServer" element={<PayerServer />} />
          <Route path="/recipientsServer" element={<RecipientsServer />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/GetFilesById/:fileId" element={<CopiedFilesById />} />
        </Routes>
      </Container>
    </BrowserRouter>
  );
}

export default App;
