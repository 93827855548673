export async function deleteFile(id: string, callback?: (_: string) => void) {
  const confirmDelete = window.confirm("Are you sure you want to delete?");

  if (!confirmDelete) return;

  try {
    await fetch(
      window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/File/" + id,
      {
        method: "DELETE",
      }
    );
    callback && callback(id);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
