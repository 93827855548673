import { useState, useEffect } from "react";
import moment from "moment-timezone";
import { ICopiedFile } from "../Config/Types";
import CopiedFileTable from "../Components/File/CopiedFileTable";
function CopiedFiles() {
  const [data, setData] = useState<ICopiedFile[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const response = await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/File"
      );
      const result: ICopiedFile[] = await response.json();
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      result.forEach((file) => {
        const parsedDate = moment(file.insertedAt);
        const userLocalDate = parsedDate.tz(userTimeZone).format("LLL");
        file.insertedAt = userLocalDate;
      });
      result.reverse();
      setData(result);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }

  if (!data) {
    return <div>No data available</div>;
  }

  let keys: string[] = [];
  if (data.length > 0) {
    keys = Object.keys(data[0]);
    keys.pop();
  }

  return (
    <div>
      <h1>CopiedFiles</h1>
      <CopiedFileTable files={data} deleteCallback={() => fetchData()} />
    </div>
  );
}

export default CopiedFiles;
