import { ICopiedFile } from "../../Config/Types";
import CopiedFileHeaders from "./CopiedFileHeaders";
import CopiedFileTableRow from "./CopiedFileTableRow";

type Props = { files: ICopiedFile[]; deleteCallback?: (_: string) => void };

const CopiedFileTable = ({ files, deleteCallback }: Props) => {
  return (
    <table className="table table-bordered table-striped mt-3">
      <CopiedFileHeaders />
      <tbody>
        {files.map((file) => (
          <CopiedFileTableRow file={file} deleteCallback={deleteCallback} />
        ))}
      </tbody>
    </table>
  );
};

export default CopiedFileTable;
