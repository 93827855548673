import React, { useState, useEffect } from "react";
import SubscriptionsById from "./SubscriptionById";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
function Subscriptions() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [payers, setPayers] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [selectedPayer, setSelectedPayer] = useState("");
  const [selectedRecipient, setSelectedRecipient] = useState("");
  const [editingSubscriptionId, setEditingSubscriptionId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [payerySubmitDetails, SetPayerSubmitDetails] = useState("");
  const [recipientSubmitDetails, SetRecipientSubmitDetails] = useState("");

  const openDetailsModal = (subscription) => {
    setSelectedSubscription(subscription);
    setShowModal(true);
  };

  const closeDetailsModal = () => {
    setSelectedSubscription(null);
    setShowModal(false);
  };
  const selectedPayerDetails = payers.find(
    (payer) => payer.id === selectedPayer
  );
  const selectedRecipientDetails = recipients.find(
    (recipient) => recipient.id === selectedRecipient
  );

  const openModal = (subscriptionId) => {
    setEditingSubscriptionId(subscriptionId);
  };

  const closeModal = () => {
    setEditingSubscriptionId(null);
  };

  async function fetchData() {
    try {
      const payerResponse = await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/PayerServer"
      );
      const payerData = await payerResponse.json();
      setPayers(payerData);

      const recipientResponse = await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/RecipientServer"
      );
      const recipientData = await recipientResponse.json();
      setRecipients(recipientData);

      const subscriptionResponse = await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/Subscription"
      );
      const subscriptionData = await subscriptionResponse.json();
      setData(subscriptionData);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      payerId: payerySubmitDetails,
      recipientId: recipientSubmitDetails,
    };
    try {
      const response = await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/Subscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      fetchData();
      SetPayerSubmitDetails("");
      SetRecipientSubmitDetails("");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");

    if (!confirmDelete) {
      return;
    }

    try {
      await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/Subscription/" + id,
        {
          method: "DELETE",
        }
      );
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const renderPayerTable = (payerData) => {
    if (!payerData) return null;

    const orderedKeys = [
      "id",
      "host",
      "port",
      "userName",
      "folderDir",
      "name",
      "oneDrivePath",
    ];
    const rows = [];

    orderedKeys.forEach((key) => {
      if (payerData.hasOwnProperty(key)) {
        let cellValue = payerData[key];

        if (typeof cellValue === "object") {
          cellValue = JSON.stringify(cellValue);
        }

        rows.push(<td key={key}>{cellValue}</td>);
      }
    });

    return rows;
  };

  const renderTable = (itemData) => {
    if (!itemData) return null;

    const rows = [];

    for (const [property, value] of Object.entries(itemData)) {
      if (property === "payerId" || property === "recipientId") continue;
      if (property === "password") continue;
      let cellValue = value;

      if (property === "payer" || property === "recipient") {
        cellValue = value.name;
      } else if (typeof value === "object") {
        cellValue = JSON.stringify(value);
      }

      rows.push(<td key={property}>{cellValue}</td>);
    }

    return rows;
  };

  const toggleContent = (index) => {
    setShowContent(showContent === index ? null : index);
  };

  if (loading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }

  if (!data) {
    return <div>No data available</div>;
  }

  const PutData = async (e) => {
    e.preventDefault();
    const id = editingSubscriptionId;
    const payload = {
      id,
      payerId: selectedPayerDetails.id,
      recipientId: selectedRecipientDetails.id,
    };
    try {
      const response = await fetch(
        window._env_["REACT_APP_ERA_FILE_TRANSFER_API"] + "/Subscription/" + id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      fetchData();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    closeModal();
    fetchData();
  };

  return (
    <div>
      <h1>New Subscription</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Select
          value={payerySubmitDetails}
          onChange={(e) => SetPayerSubmitDetails(e.target.value)}
        >
          <option value="" disabled>
            Select a Payer
          </option>
          {payers.map((payer) => (
            <option
              key={payer.id}
              value={payer.id}
            >{`${payer.id} - ${payer.folderDir}`}</option>
          ))}
        </Form.Select>
        <Form.Select
          value={recipientSubmitDetails}
          onChange={(e) => SetRecipientSubmitDetails(e.target.value)}
        >
          <option value="" disabled>
            Select a Recipient
          </option>
          {recipients.map((recipient) => (
            <option
              key={recipient.id}
              value={recipient.id}
            >{`${recipient.id} - ${recipient.folderDir}`}</option>
          ))}
        </Form.Select>
        <Button type="submit">Submit</Button>
      </Form>
      <br />
      <div>
        <h3>Get By ID</h3>
        <SubscriptionsById />
      </div>
      <h1>Subscriptions</h1>
      {data.map((subscription, index) => (
        <div key={index}>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                {Object.keys(data[0]).map((property) => {
                  if (property === "payerId" || property === "recipientId")
                    return null;
                  return (
                    <th key={property} scope="col">
                      {property}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {renderTable(subscription)}
                <td>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(subscription.id)}
                  >
                    Delete
                  </Button>
                </td>
                <td>
                  <Button
                    variant="primary"
                    onClick={() => openModal(subscription.id)}
                  >
                    Edit
                  </Button>
                </td>
                <td>
                  <td>
                    <Modal
                      show={editingSubscriptionId === subscription.id}
                      onHide={closeModal}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Subscription</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={PutData}>
                          <Form.Select
                            value={selectedPayer}
                            onChange={(e) => setSelectedPayer(e.target.value)}
                          >
                            <option value="" disabled>
                              Select a Payer
                            </option>
                            {payers.map((payer) => (
                              <option
                                key={payer.id}
                                value={payer.id}
                              >{`ID: ${payer.id} - USER: ${payer.userName}`}</option>
                            ))}
                          </Form.Select>
                          <Form.Select
                            value={selectedRecipient}
                            onChange={(e) =>
                              setSelectedRecipient(e.target.value)
                            }
                          >
                            <option value="" disabled>
                              Select a Recipient
                            </option>
                            {recipients.map((recipient) => (
                              <option
                                key={recipient.id}
                                value={recipient.id}
                              >{`ID: ${recipient.id} - USER: ${recipient.userName}`}</option>
                            ))}
                          </Form.Select>
                          <Button type="submit">Submit</Button>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                          Cancel
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </td>
                </td>
                <td>
                  <Button
                    variant="info"
                    onClick={() => openDetailsModal(subscription)}
                  >
                    Show Details
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
      <Modal
        show={showModal}
        onHide={closeDetailsModal}
        dialogClassName="wider-modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-header">
            Subscription Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="wider-modal-content">
          {selectedSubscription && (
            <>
              <h4>Payer</h4>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Host</th>
                    <th>Port</th>
                    <th>Username</th>
                    <th>Folder Dir</th>
                    <th>Name</th>
                    <th>OneDrivePath</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>{renderPayerTable(selectedSubscription.payer)}</tr>
                </tbody>
              </table>
              <h4>Recipient</h4>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Host</th>
                    <th>Port</th>
                    <th>Username</th>
                    <th>Folder Dir</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>{renderTable(selectedSubscription.recipient)}</tr>
                </tbody>
              </table>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDetailsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Subscriptions;
